var data = [];

data['order'] = [{
  id: "order",
  text: "Đơn hàng",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/order'
      }
    },
    {
      text: "Tìm kiếm",
      data: {
        method: 'GET',
        endpoint: '/order/search'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/order/{id}'
      }
    },
    {
      text: "Mới",
      data: {
        method: 'GET',
        endpoint: '/order?order_status=new'
      }
    },
    {
      text: "Đã xác nhận",
      data: {
        method: 'GET',
        endpoint: '/order?order_status=confirm'
      }
    },
    {
      text: "Chưa thanh toán",
      data: {
        method: 'GET',
        endpoint: '/order?order_status=confirm&payment_status=0'
      }
    },
    {
      text: "Hoàn tất",
      data: {
        method: 'GET',
        endpoint: '/order?order_status=done'
      }
    },
    {
      text: "Bị hoàn trả",
      data: {
        method: 'GET',
        endpoint: '/order?order_status=return'
      }
    },
    {
      text: "Bị hủy",
      data: {
        method: 'GET',
        endpoint: '/order?order_status=cancel'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'GET',
        endpoint: '/order/create'
      }
    },
    {
      text: "Sửa",
      data: {
        method: 'PUT',
        endpoint: '/order/{id}'
      }
    }
  ]
}];
data['product'] = [{
  id: "product",
  text: "Sản phẩm",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/product'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/product/{id}'
      }
    },
    {
      text: "Thêm",
      data: {
        method: 'GET',
        endpoint: '/product/create'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/product'
      }
    },
    {
      text: "Cập nhật",
      data: {
        method: 'PUT',
        endpoint: '/product/{id}'
      }
    },
    {
      text: "Xoá",
      data: {
        method: 'DELETE',
        endpoint: '/product'
      }
    },
    {
      text: "Nhân bản",
      data: {
        method: 'POST',
        endpoint: '/product/duplicate/{id}'
      }
    }

  ]
}];
data['collection'] = [{
  id: "collection",
  text: "Nhóm sản phẩm",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/collection'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/collection/{id}'
      }
    },
    {
      text: "Thêm",
      data: {
        method: 'GET',
        endpoint: '/collection/create'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/collection'
      }
    },
    {
      text: "Cập nhật",
      data: {
        method: 'PUT',
        endpoint: '/collection/{id}'
      }
    },
    {
      text: "Xoá",
      data: {
        method: 'DELETE',
        endpoint: '/collection'
      }
    }
  ]
}];
data['product_buy_together'] = [{
  id: "product_buy_together",
  text: "Sản phẩm mua kèm",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/product_buy_together'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/product_buy_together/{id}'
      }
    },
    {
      text: "Thêm",
      data: {
        method: 'GET',
        endpoint: '/product_buy_together/create'
      }
    },
    {
      text: "Thêm trong trang sản phẩm",
      data: {
        method: 'POST',
        endpoint: '/product_buy_together/one'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/product_buy_together'
      }
    },
    {
      text: "Cập nhật",
      data: {
        method: 'PUT',
        endpoint: '/product_buy_together/{id}'
      }
    },
    {
      text: "Xoá",
      data: {
        method: 'DELETE',
        endpoint: '/product_buy_together/{id}'
      }
    }
  ]
}];
data['import_product'] = [{
  id: "import_product",
  text: "Nhập sản phẩm",
  state: {
    opened: true
  },
  data: 'parent',
  children: [{
      text: "Nhập sản phẩm",
      data: {
        method: 'GET',
        endpoint: '/import_product'
      }
    },
    {
      text: "Download file mẫu",
      data: {
        method: 'GET',
        endpoint: '/import_product/template'
      }
    },
    {
      text: "Import",
      data: {
        method: 'POST',
        endpoint: '/import_product'
      }
    }
  ]
}];
data['attribute'] = [{
  id: "attribute",
  text: "Thuộc tính sản phẩm",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/attribute'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/attribute/{id}'
      }
    },
    {
      text: "Thêm",
      data: {
        method: 'GET',
        endpoint: '/attribute/create'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/attribute'
      }
    },
    {
      text: "Cập nhật",
      data: {
        method: 'PUT',
        endpoint: '/attribute/{id}'
      }
    },
    {
      text: "Xoá",
      data: {
        method: 'DELETE',
        endpoint: '/attribute/{id}'
      }
    }
  ]
}];
data['coupon'] = [{
  id: "coupon",
  text: "Mã giảm giá",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/coupon'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/coupon/{id}'
      }
    },
    {
      text: "Thêm",
      data: {
        method: 'GET',
        endpoint: '/coupon/create'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/coupon'
      }
    },
    {
      text: "Cập nhật",
      data: {
        method: 'PUT',
        endpoint: '/coupon/{id}'
      }
    },
    {
      text: "Xoá",
      data: {
        method: 'DELETE',
        endpoint: '/coupon'
      }
    }
  ]
}];
data['sale'] = [{
  id: "sale",
  text: "Chương trình giảm giá",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/sale'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/sale/{id}'
      }
    },
    {
      text: "Thêm",
      data: {
        method: 'GET',
        endpoint: '/sale/create'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/sale'
      }
    },
    {
      text: "Cập nhật",
      data: {
        method: 'PUT',
        endpoint: '/sale/{id}'
      }
    },
    {
      text: "Xoá",
      data: {
        method: 'DELETE',
        endpoint: '/sale'
      }
    }
  ]
}];
data['customer'] = [{
  id: "customer",
  text: "Khách hàng",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/customer'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/customer/{id}'
      }
    },
    {
      text: "Cập nhật",
      data: {
        method: 'PUT',
        endpoint: '/customer/{id}'
      }
    },
    {
      text: "Export",
      data: {
        method: 'GET',
        endpoint: '/customer/export'
      }
    },
    {
      text: "Xem tất cả đơn hàng",
      data: {
        method: 'GET',
        endpoint: '/customer/order/{id}'
      }
    },
    {
      text: "Xoá",
      data: {
        method: 'DELETE',
        endpoint: '/customer'
      }
    }
  ]
}];
data['article'] = [{
  id: "article",
  text: "Bài viết",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/article'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/article/{id}'
      }
    },
    {
      text: "Thêm",
      data: {
        method: 'GET',
        endpoint: '/article/create'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/article'
      }
    },
    {
      text: "Cập nhật",
      data: {
        method: 'PUT',
        endpoint: '/article/{id}'
      }
    },
    {
      text: "Xoá",
      data: {
        method: 'DELETE',
        endpoint: '/article'
      }
    },
    {
      text: "Nhân bản",
      data: {
        method: 'POST',
        endpoint: '/article/duplicate/{id}'
      }
    }
  ]
}];
data['blog'] = [{
  id: "blog",
  text: "Nhóm bài viết",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/blog'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/blog/{id}'
      }
    },
    {
      text: "Thêm",
      data: {
        method: 'GET',
        endpoint: '/blog/create'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/blog'
      }
    },
    {
      text: "Cập nhật",
      data: {
        method: 'PUT',
        endpoint: '/blog/{id}'
      }
    },
    {
      text: "Xoá",
      data: {
        method: 'DELETE',
        endpoint: '/blog'
      }
    }
  ]
}];
data['page'] = [{
  id: "page",
  text: "Trang nội dung",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/page'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/page/{id}'
      }
    },
    {
      text: "Thêm",
      data: {
        method: 'GET',
        endpoint: '/page/create'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/page'
      }
    },
    {
      text: "Cập nhật",
      data: {
        method: 'PUT',
        endpoint: '/page/{id}'
      }
    },
    {
      text: "Xoá",
      data: {
        method: 'DELETE',
        endpoint: '/page'
      }
    }
  ]
}];
data['gallery'] = [{
  id: "gallery",
  text: "Gallery",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/gallery'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/gallery/{id}'
      }
    },
    {
      text: "Thêm mới",
      data: {
        method: 'GET',
        endpoint: '/gallery/create'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/gallery'
      }
    },
    {
      text: "Cập nhật",
      data: {
        method: 'PUT',
        endpoint: '/gallery/{id}'
      }
    },
    {
      text: "Xoá",
      data: {
        method: 'DELETE',
        endpoint: '/gallery'
      }
    }
  ]
}];
data['photo'] = [{
  id: "photo",
  text: "Photo",
  state: {
    opened: true
  },
  data: 'parent',
  children: [{
      text: "Trang tạo mới",
      data: {
        method: 'GET',
        endpoint: '/photo/create'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/photo/{id}'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/photo'
      }
    },
    {
      text: "Cập nhật",
      data: {
        method: 'PUT',
        endpoint: '/photo/{id}'
      }
    },
    {
      text: "Xoá",
      data: {
        method: 'DELETE',
        endpoint: '/photo/{id}'
      }
    }
  ]
}];
data['review'] = [{
  id: "review",
  text: "Đánh giá",
  state: {
    opened: true
  },
  data: 'parent',
  children: [{
    text: "Danh sách",
    data: {
      method: 'GET',
      endpoint: '/review'
    }
  }
  ]
}];

data['comment'] = [{
  id: "comment",
  text: "Bình luận",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Xem tất cả",
      data: {
        method: 'GET',
        endpoint: '/comment'
      }
    },
    {
      text: "Thuộc sản phẩm",
      data: {
        method: 'GET',
        endpoint: '/comment/product'
      }
    },
    {
      text: "Thuộc bài viết",
      data: {
        method: 'GET',
        endpoint: '/comment/article'
      }
    },
    {
      text: "Trả lời",
      data: {
        method: 'PUT',
        endpoint: '/comment/{id}'
      }
    },
    {
      text: "Xoá",
      data: {
        method: 'DELETE',
        endpoint: '/comment'
      }
    }
  ]
}];
data['contact'] = [{
  id: "contact",
  text: "Liên hệ",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Xem tất cả",
      data: {
        method: 'GET',
        endpoint: '/contact'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/contact/{id}'
      }
    },
    {
      text: "Mới",
      data: {
        method: 'GET',
        endpoint: '/contact?read=1'
      }
    },
    {
      text: "Chưa phản hồi",
      data: {
        method: 'GET',
        endpoint: '/contact?reply=1'
      }
    },
    {
      text: "Cập nhật trạng thái",
      data: {
        method: 'PUT',
        endpoint: '/contact/updateStatus'
      }
    },
    {
      text: "Xóa",
      data: {
        method: 'DELETE',
        endpoint: '/contact/{id}'
      }
    }
  ]
}];
data['menu'] = [{
  id: "menu",
  text: "Menu",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/menu'
      }
    },
    {
      text: "Tạo menu",
      data: {
        method: 'GET',
        endpoint: '/menu/create'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/menu/{id}'
      }
    },
    {
      text: "Cập nhật độ ưu tiên",
      data: {
        method: 'PUT',
        endpoint: '/menu/updatePriority'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/menu'
      }
    },
    {
      text: "Sửa",
      data: {
        method: 'PUT',
        endpoint: '/menu/{id}'
      }
    },
    {
      text: "Xóa một menu",
      data: {
        method: 'DELETE',
        endpoint: '/menu/{id}'
      }
    },
    {
      text: "Xóa nhiều menu",
      data: {
        method: 'DELETE',
        endpoint: '/menu'
      }
    }
  ]
}];
data['setting'] = [{
  id: "setting",
  text: "Thiết lập chung",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Xem",
      data: {
        method: 'GET',
        endpoint: '/setting'
      }
    },
    {
      text: "Sửa",
      data: {
        method: 'PUT',
        endpoint: '/setting'
      }
    }
  ]
}];
data['library'] = [{
  id: "library",
  text: "Library",
  state: {
    opened: true
  },
  data: 'parent',
  children: [{
      text: "Xem",
      data: {
        method: 'GET',
        endpoint: '/library'
      }
    }
  ]
}];
data['shipping_fee'] = [{
  id: "shipping_fee",
  text: "Phí vận chuyển",
  state: {
    opened: true
  },
  data: 'parent',
  children: [{
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/shipping_fee'
      }
    },
    {
      text: "Chi tiết",
      data: {
        method: 'GET',
        endpoint: '/shipping_fee/edit/{id}'
      }
    },
    {
      text: "Thêm",
      data: {
        method: 'GET',
        endpoint: '/shipping_fee/create'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/shipping_fee'
      }
    },
    {
      text: "Cập nhật",
      data: {
        method: 'PUT',
        endpoint: '/shipping_fee/{id}'
      }
    },
    {
      text: "Xóa",
      data: {
        method: 'DELETE',
        endpoint: '/shipping_fee/{id}'
      }
    }
  ]
}];
data['testimonial'] = [{
  id: "testimonial",
  text: "Đối tác nói về chúng tôi",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/testimonial'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/testimonial/{id}'
      }
    },
    {
      text: "Thêm mới",
      data: {
        method: 'GET',
        endpoint: '/testimonial/create'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/testimonial'
      }
    },
    {
      text: "Cập nhật",
      data: {
        method: 'PUT',
        endpoint: '/testimonial/{id}'
      }
    },
    {
      text: "Xoá",
      data: {
        method: 'DELETE',
        endpoint: '/testimonial'
      }
    }
  ]
}];
data['client'] = [{
  id: "client",
  text: "Khách hàng tiêu biểu",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/client'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/client/{id}'
      }
    },
    {
      text: "Thêm mới",
      data: {
        method: 'GET',
        endpoint: '/client/create'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/client'
      }
    },
    {
      text: "Cập nhật",
      data: {
        method: 'PUT',
        endpoint: '/client/{id}'
      }
    },
    {
      text: "Xoá",
      data: {
        method: 'DELETE',
        endpoint: '/client'
      }
    }
  ]
}];
data['user'] = [{
  id: "user",
  text: "Quản trị viên",
  state: {
    opened: true
  },
  data: 'parent',
  children: [
    {
      text: "Danh sách",
      data: {
        method: 'GET',
        endpoint: '/user'
      }
    },
    {
      text: "Lịch sử hoạt động",
      data: {
        method: 'GET',
        endpoint: '/user/history'
      }
    },
    {
      text: "Thêm",
      data: {
        method: 'GET',
        endpoint: '/user/create'
      }
    },
    {
      text: "Tạo",
      data: {
        method: 'POST',
        endpoint: '/user'
      }
    },
    {
      text: "Xem chi tiết",
      data: {
        method: 'GET',
        endpoint: '/user/{id}'
      }
    },
    {
      text: "Sửa",
      data: {
        method: 'PUT',
        endpoint: '/user/{id}'
      }
    },
    {
      text: "Nhận email khi có đơn hàng",
      data: {
        method: 'GET',
        endpoint: '/user/email/order'
      }
    },
    {
      text: "Nhận email khi có liên hệ",
      data: {
        method: 'GET',
        endpoint: '/user/email/contact'
      }
    },
    {
      text: "Xóa",
      data: {
        method: 'DELETE',
        endpoint: '/user/{id}'
      }
    }
  ]
}];
export default data
