<template lang="html">
  <div class="row">

  </div>
</template>

<script>
import { Message, Input, Tree } from 'element-ui';
import dataFrom from './role-form'

let _form = {};

export default {
  components: {
    ElInput: Input,
    ElTree: Tree
  },
  beforeCreate() {
    window.AP = this;
  },
  data () {
    return {
      form: _form,
    }
  },
  mounted() {
  },
  methods: {
    show() {
    },
    save() {
      this.$store.dispatch('storeRole', this.form);
    }
  }
}
</script>
